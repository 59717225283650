
import { Component, Vue } from 'vue-property-decorator'
import { SiteStateModule } from '@/store/site_state'
import { EVENT_GLOBAL_GOTO_PAGE } from '@/event/event_types'

@Component({
  components: {}
})
export default class headertab extends Vue {
  get tablist() {
    return SiteStateModule.menutablist
  }
  get menuname() {
    return SiteStateModule.menuname
  }
  tab: any = {}
  show = false
  style: any = {
    position: 'absolute',
    zIndex: '9',
    borderRadius: '4px',
    padding: '5px 0',
    boxShadow: '2px 2px 3px 0 rgba(0,0,0,.3)'
  }
  menuclick(key: any) {
    console.log(key)

    if (key.key === '1') {
      SiteStateModule.updatemenutabs(this.tab)
      this.$eventBus.$emit(EVENT_GLOBAL_GOTO_PAGE, {
        path: this.tab.resourceUrl
      })
    }
    if (key.key === '2') {
      SiteStateModule.updatemenutabs(null)
    }
    this.show = false
  }
  mouseleave() {
    this.show = false
  }
  public handleChangetag(item: any) {
    SiteStateModule.updatemenutab(item)
    this.$eventBus.$emit(EVENT_GLOBAL_GOTO_PAGE, {
      path: item.resourceUrl
    })
  }
  rightClick(e: any, item: any) {
    this.tab = item
    this.show = false
    console.log(e)
    this.style['left'] = e.pageX - 230 + 'px'
    this.style['top'] = e.layerY + 'px'
    console.log(this.style)

    this.show = true
  }
  public closetab(item: any) {
    if (this.tablist.indexOf(item) > 0 && this.menuname === item.name) {
      let index = this.tablist[this.tablist.indexOf(item) - 1]
      this.$eventBus.$emit(EVENT_GLOBAL_GOTO_PAGE, {
        path: index.resourceUrl
      })
    }
    if (this.tablist.indexOf(item) === 0 && this.menuname === item.name) {
      let index = this.tablist[this.tablist.indexOf(item) + 1]
      if (index) {
        this.$eventBus.$emit(EVENT_GLOBAL_GOTO_PAGE, {
          path: index.resourceUrl
        })
      }
    }
    SiteStateModule.deletmenutab(item)
    console.log(this.tablist)
    this.$forceUpdate()
  }
}
